<template>
  <div>
    <!-- 分页 -->
    <div class="paging">
      <div>
        <el-pagination
          style="margin-top: 1.5rem"
          layout="total,prev, pager, next, jumper "
          @current-change="handleCurrentChange"
          :page-count="parseInt(this.totalPages)"
          :total="parseInt(this.totalCount)"
          :page-size="parseInt(this.value.amount)"
          background
          :hide-on-single-page="true"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";

export default {
  name: "",

  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalCount: "",
      totalPages: "",
    };
  },

  created() {
    // console.log("path", this.value.path);
    let params = {
        s: 2,
        c: this.value.path,
        pageNo: 1,
        pageSize: this.value.amount,
      }
    api
      .post("spc/cms/publish/queryList.do", params)
      .then((res) => {
        this.totalCount = res.totalCount;
        this.totalPages = res.totalPages;
        if (res.result.length !== 0) {
          this.$emit("transfer", res.result);
        } else {
          this.$message({
            message: "暂无数据",
            type: "warning",
          });
        }
      });
  },
  watch: {
    $route(to) {
      //监听路由变化的时候使滚动条回到顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.getData(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    getData(val) {
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.value.path,
          pageNo: val,
          pageSize: this.value.amount,
        })
        .then((res) => {
          this.$emit("transfer", res.result);
        });
    },
  },
};
</script>

<style lang='scss'>
.paging {
  text-align: center;
  margin: 1rem 0;
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    display: inline-block;
    font-size: 1rem;
    min-width: 2.25rem;
    height: 1.75rem;
    line-height: 1.75rem;
    vertical-align: top;
    box-sizing: border-box;
  }
  .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    display: block;
    font-size: 1rem;
    font-weight: 700;
  }
  .el-pager li {
    padding: 0 0.25rem;
    background: #fff;
    font-size: 0.8125rem;
    min-width: 2.25rem;
    height: 1.75rem;
    line-height: 1.75rem;
    box-sizing: border-box;
    text-align: center;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    margin: 0 0.3125rem;
    background-color: #f4f4f5;
    color: #606266;
    min-width: 1.875rem;
    border-radius: 2px;
  }
  .el-pagination__editor.el-input {
    width: 3.125rem;
  }
  .el-pagination__editor.el-input .el-input__inner {
    height: 1.75rem;
    font-size: 1rem;
  }
  .el-pagination__jump .el-input__inner {
    padding: 0 0.1875rem;
  }
  .el-pagination__editor {
    line-height: 1.125rem;
    padding: 0 0.125rem;
    height: 1.75rem;
    text-align: center;
    margin: 0 0.125rem;
    box-sizing: border-box;
    border-radius: 3px;
  }
}
</style>