<template>
  <div
    class="bksj_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <!-- 导航 -->

    <div class="main">
      <!-- 首页>社科视频>报刊视界 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col >
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/bksj">报刊世界</el-breadcrumb-item>
            <el-breadcrumb-item>{{headtion}}</el-breadcrumb-item>
          </el-breadcrumb>

          <!-- 标题 -->

          <!-- <div class="woshuoHeadline">
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div class="title">{{ headtion }}</div>
                <div class="line">
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
              </el-col>
            </el-row>
          </div> -->
          <ListHeadline :title="headtion"/>

          <!-- 列表 -->
          <div class="page_k">
            <!-- 单列表 -->
            <div class="soooooo">
              <el-row
                type="flex"
                justify="center"
                v-for="a in list"
                :key="a.id"
              >
                <el-col :span="24">
                  <router-link target="_blank"
                    :to="{
                      path: '/xq',
                      query: {
                        qing: a.id,
                        bkhead: headtion 
                      },
                    }"
                  >
                    <div class="page_top">
                      <el-row :gutter="$store.state.isEquipment == 'pc'?22:0">
                        <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                          <div class="page_top_img">
                            <img  v-lazy="a.pub_cover" alt="" />
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                          <div class="shu_zi">
                            <p class="left_zi">{{ a.SYS_TOPIC }}</p>
                            <div class="zhong_zi">
                              <span class="text">
                                {{
                                  $AwayTell(a.DESCRIPTION,0,50)
                                }}
                              </span>
                              <span class="particulars">[详细]</span>
                            </div>
                            <p class="right_zi">
                              {{ $ModuleDate(a.RECORD_TIME) }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="loading">
        <p class="busy" v-show="busy">加载中...</p>
        <p class="finish" v-show="finish">没有更多了</p>
      </div>
    </div>
  </div>
</template>

<script>
import ListHeadline from "@/components/ListHeadline.vue";
import api from "@/api/index.js";
export default {
  props: {
    msg: String,
  },
  components: {
    ListHeadline,
  },
  data () {
    return {
      headtion: "访谈",
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
    };
  },
  watch: {
    $route: {
      handler () {
        this.load();
      },
    },
  },
  created () {
    this.getTitle(this.$route.query.id);
  },
  methods: {
    load () {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData () {
      if(this.finish){
        return;
      }
      this.busy= true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: this.$route.query.id,
          pageNo: this.pageNo,
          pageSize: 9,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy= false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
    getTitle (val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "47":
          this.headtion = "我说";
          break;
        case "48":
          this.headtion = "融合报道";
          break;
        case "49":
          this.headtion = "视评";
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 .625rem;
}

a {
  color: #42b983;
}

.bksj_phone {
  background: #f9f9f9;
  padding:0 0.5rem;
  .crumbs {
    padding-top: 1.25rem;
  }

  // .woshuoHeadline {
  //   margin-top: 0.875rem;

  //   .title {
  //     font-size: 1.875rem;
  //     font-weight: bold;
  //     color: #212121;
  //     line-height: 2.8125rem;
  //     padding-left: .3125rem;
  //   }

  //   .line {
  //     .left {
  //       height: 0rem;
  //       width: 4rem;
  //       border-bottom: .3125rem solid #2181b0;
  //       border-right: .3125rem solid transparent;
  //       border-left: .3125rem solid #2181b0;
  //       display: inline-block;
  //     }

  //     .right {
  //       width: calc(100% - 6rem);
  //       border-bottom: 1px solid #2181b0;
  //       display: inline-block;
  //       margin-left: 0.5rem;
  //     }
  //   }
  // }

  .main {
    .maysk {
      font-size: 1.875rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      margin-bottom: 1.25rem;
    }

    .line {
      .line_l {
        width: 4.5rem;
        height: .3125rem;
        background: linear-gradient(237deg, transparent 5px, #2181b0 0) top right;
      }
    }

    .page_k {
      .page_top {
        background: #ffffff;
        margin-top: 1rem;
        padding: 1.25rem;
        position: relative;

        .page_top_img {
          // height: 11rem;

          img {
            width: 100%;
            aspect-ratio:4/3;
          }
        }

        .shu_zi {
          color: #212121;
          height: 100%;

          .left_zi {
            font-size: 1.25rem;
            line-height: 2rem;
            margin-top: 1rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .zhong_zi {
            margin-top: 0.5rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 1.875rem;

            .particulars {
              color: #287498;
              font-size: 1rem;
              cursor: pointer;
              margin-left: .3125rem;
            }
          }

          .right_zi {
            margin-top: 1rem;
            color: #c29460;
          }
        }
      }
    }
  }

  .loading {
    margin: 0.5rem 0;

    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
}</style>
