<template>
  <div class="fangtan">
    <!-- 导航 -->

    
    <div class="main mainWidth" >
      <!-- 首页>社科视频>报刊视界 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col >
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/bksj">报刊视界</el-breadcrumb-item>
            <el-breadcrumb-item>{{ headtion }}</el-breadcrumb-item>
          </el-breadcrumb>

          <!-- 标题 -->

          <div class="woshuoHeadline">
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div class="title">{{ headtion }}</div>
                <div class="line">
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- 列表 -->
          <div class="page_k">
            <!-- 单列表 -->
            <div class="soooooo">
              <el-row
                type="flex"
                justify="center"
                v-for="a in list"
                :key="a.id"
              >
                <el-col :span="24">
                  <router-link target="_blank" :to="{ path: '/xq', query: { qing: a.id, mian: 'shi', bkhead: headtion } }">
                    <div class="page_top">
                      <el-row :gutter="$store.state.isEquipment == 'pc'?22:0">
                        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                          <div class="page_top_img">
                            <img  v-lazy="a.pub_cover" alt="" />
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="17" :md="17" :lg="17" :xl="17">
                          <div class="shu_zi">
                            <p class="left_zi">{{ a.SYS_TOPIC }}</p>
                            <div class="zhong_zi">
                              <span class="text">
                                {{ $AwayTell(a.DESCRIPTION,0,$store.state.isSmallPc?80:110) }}
                              </span>
                              <span class="particulars">[详细]</span>
                            </div>
                            
                            <p class="right_zi">
                              <!-- 2023-02-27 {{ a.TIME_LENGTH }} -->
                              {{ $ModuleDate(a.RECORD_TIME) }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </div>
          <Paging :value="value" @transfer="getData" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ViceHeadline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    Paging,
  },
  data () {
    return {
      headtion: "",
      list: [],
      value: {
        path: this.$route.query.id + "",
        amount: "9",
      },
    };
  },

  created () {
    this.getTitle(this.$route.query.id);
  },
  methods: {
    getTitle (val) {
      this.path = val;
      switch (this.$route.query.id) {
        case "47":
          this.headtion = "我说";
          break;
        case "48":
          this.headtion = "融合报道";
          break;
        case "49":
          this.headtion = "视评";
          break;
        default:
          break;
      }
    },

    getData (msg) {
      this.list = msg;
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 .625rem;
}

a {
  color: #42b983;
}

.fangtan {
  background: #f9f9f9;

  .woshuoHeadline {
    margin-top: 0.875rem;

    .title {
      font-size: 1.875rem;
      font-weight: bold;
      color: #212121;
      line-height: 2.8125rem;
      padding-left: .3125rem;
    }

    .line {
      .left {
        height: 0rem;
        width: 4rem;
        border-bottom: .3125rem solid #2181b0;
        border-right: .3125rem solid transparent;
        border-left: .3125rem solid #2181b0;
        display: inline-block;
      }

      .right {
        width: calc(100% - 6rem);
        border-bottom: .0625rem solid #2181b0;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }

  .main {

    .maysk {
      font-size: 1.875rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      margin-bottom: 1.25rem;
    }

    .line {
      .line_l {
        width: 4.5rem;
        height: .3125rem;
        background: linear-gradient(237deg, transparent 5px, #2181b0 0) top right;
      }

      .line_r {
        width: 82.5rem;
        height: .0625rem;
        background: #668594;
        margin-left: .1875rem;
      }
    }

    .page_k {
      .page_top {
        background: #ffffff;
        margin-top: .625rem;
        padding: 1.25rem;
        position: relative;

        .page_top_img {
          
          img {
            width: 100%;
            height:243px;
          }
        }

        .shu_zi {
          color: #212121;
          height: 100%;

          .left_zi {
            font-size: 1.25rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .zhong_zi {
            margin-top: 1rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 1.875rem;

            .particulars {
              color: #287498;
              font-size: 1rem;
              cursor: pointer;
              margin-left: .3125rem;
            }
          }

          .right_zi {
            position: absolute;
            bottom: 0%;
            color: #c29460;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 1440px) {
  .fangtan .main .page_k .page_top .page_top_img img{
    height:169px;
  }
}
</style>
