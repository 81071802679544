<template>
  <div>
    <Normal v-if="this.$store.state.isEquipment == 'phone' ? false : true"/>
    <Phone v-if="this.$store.state.isEquipment == 'phone' ? true : false"/>
  </div>
</template>

<script>
import Normal from './Normal.vue'
import Phone from './Phone.vue'
export default {
  components: {
    Normal,
    Phone
  }
}
</script>